import { combineReducers } from "redux";
import { authReducer } from "../reducers/auth";
import { localeReducer } from "../reducers/locale";
import { AuthAction } from "../actions/auth";
import { LocaleAction } from "../actions/locale";
import { repositoryReducer } from "../reducers/repository";
import { RepositoryAction } from "../actions/repository";

/**
 * Root reducer that wraps all Redux reducers
 */
export const rootReducer = combineReducers({
    auth: authReducer,
    locale: localeReducer,
    repository: repositoryReducer
});

/**
 * Type for Redux state
 */
export type ReduxState = ReturnType<typeof rootReducer>;

/**
 * Union type for Redux actions
 */
export type ReduxActions = AuthAction | LocaleAction | RepositoryAction;