import { PredefinedElement } from "../actions/repository";
import { PulledProperty } from "../generated/client";
import { ContextLevel, Property } from "../types";

/**
 * Utility class for ConfigHub context
 */
export default class ContextUtils {

  /**
   * Creates full context path from predefined context elements and context slugs from path
   * 
   * @param predefinedElements predefined context elements
   * @param urlSlugs array of slugs from location path
   * @param fullContextLength full context length from the amount of context elements in repository info
   * @returns context path as string
   */
  public static createFullContext = (
    predefinedElements: PredefinedElement[],
    urlSlugs: string[],
    fullContextLength: number
  ): string => {
    return Array
      .from(Array(fullContextLength), (v, i) => {
        if (predefinedElements.length > i) {
          return predefinedElements[i].value;
        } else if (urlSlugs.length > i - predefinedElements.length) {
          return urlSlugs[i - predefinedElements.length];
        } else {
          return "*";
        }
      })
      .join(";");
  }

  /**
   * Translates context element key value pairs into list of context level objects
   * 
   * @param contextElements context elements as key value pairs
   * @param contextOrder correct context order from repository info
   * @returns list of context level objects
   */
  public static translateToContextLevels = (
    contextElements: { [key: string]: string[] },
    contextOrder: string[]
  ): ContextLevel[] => {
    return Object
      .entries(contextElements || [])
      .map(([ name, options ]) => ({ name, options }))
      .sort((a, b) => contextOrder.indexOf(a.name) - contextOrder.indexOf(b.name));
  }

  /**
   * Translates pulled properties to list of property objects
   * 
   * @param properties pulled properties as key value pairs
   * @returns list of property objects
   */
  public static translateToProperties = (properties: { [key: string]: PulledProperty } | undefined): Property[] => {
    return properties ? Object
      .entries(properties || [])
      .map(([ name, { type, context, comment, val } ]) =>
        ({
          name,
          description: comment,
          type,
          context,
          value: val ?? undefined
        })
      ) : [];
  }
}