/* tslint:disable */
/* eslint-disable */
/**
 * ConfigHub API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PushedProperty,
    PushedPropertyFromJSON,
    PushedPropertyFromJSONTyped,
    PushedPropertyToJSON,
} from './';

/**
 * 
 * @export
 * @interface PushedConfigData
 */
export interface PushedConfigData {
    /**
     * 
     * @type {string}
     * @memberof PushedConfigData
     */
    changeComment: string;
    /**
     * 
     * @type {boolean}
     * @memberof PushedConfigData
     */
    enableKeyCreation: boolean;
    /**
     * 
     * @type {Array<PushedProperty>}
     * @memberof PushedConfigData
     */
    data: Array<PushedProperty>;
}

export function PushedConfigDataFromJSON(json: any): PushedConfigData {
    return PushedConfigDataFromJSONTyped(json, false);
}

export function PushedConfigDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushedConfigData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'changeComment': json['changeComment'],
        'enableKeyCreation': json['enableKeyCreation'],
        'data': ((json['data'] as Array<any>).map(PushedPropertyFromJSON)),
    };
}

export function PushedConfigDataToJSON(value?: PushedConfigData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'changeComment': value.changeComment,
        'enableKeyCreation': value.enableKeyCreation,
        'data': ((value.data as Array<any>).map(PushedPropertyToJSON)),
    };
}


