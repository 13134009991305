import * as React from "react";
import { IconButton, TextField, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "../../styles/generic/string-list";
import strings from "../../localization/strings";
import produce from "immer";
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  list: string[];
  onUpdate: (updatedList: string[]) => void;
}

/**
 * String list component
 *
 * @param props component properties
 */
const StringList: React.FC<Props> = ({ classes, list, onUpdate }) => {

  /**
   * Event handler for add item to list
   */
  const onAddItem = () => {
    onUpdate(
      produce(list, draft => {
        draft.push("");
      })
    );
  }

  /**
   * Event handler for update item in list
   *
   * @param index item index
   * @param event React change event
   */
  const onUpdateItem = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate(
      produce(list, draft => {
        draft[index] = event.target.value;
      })
    );
  }

  /**
   * Event handler for remove item from list
   *
   * @param index item index
   */
  const onRemoveItem = (index: number) => () => {
    onUpdate(
      produce(list, draft => {
        draft.splice(index, 1);
      })
    )
  }

  /**
   * Renders list
   * 
   * @param value value
   * @param index item index
   */
  const renderListItem = (value: string, index: number) => {
    return (
      <div className={ classes.listRow }>
        <TextField
          size="medium"
          variant="outlined"
          className={ classes.textField }
          label={ strings.value }
          value={ value || "" }
          multiline
          InputProps={{ inputProps: { min: 0 } }}
          InputLabelProps={{ variant: "outlined" }}
          onChange={ onUpdateItem(index) }
        />
        <IconButton
          className={ classes.deleteButton }
          onClick={ onRemoveItem(index) }
        >
          <DeleteOutlineIcon/>
        </IconButton>
      </div>
    );
  }

  /**
   * Renders add button
   */
  const renderAdd = () => {
    return (
      <IconButton
        className={ classes.addButton }
        onClick={ onAddItem }
      >
        <AddIcon/>
      </IconButton>
    );
  }

  /**
   * Component render
   */
  return (
    <div className={ classes.root }>
      { list.map(renderListItem) }
      { renderAdd() }
    </div>
  );
}

export default withStyles(styles)(StringList);
