/* tslint:disable */
/* eslint-disable */
/**
 * ConfigHub API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Version,
    VersionFromJSON,
    VersionFromJSONTyped,
    VersionToJSON,
} from './';

/**
 * 
 * @export
 * @interface SystemInfo
 */
export interface SystemInfo {
    /**
     * 
     * @type {Version}
     * @memberof SystemInfo
     */
    version?: Version;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SystemInfo
     */
    license?: { [key: string]: string; };
}

export function SystemInfoFromJSON(json: any): SystemInfo {
    return SystemInfoFromJSONTyped(json, false);
}

export function SystemInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': !exists(json, 'version') ? undefined : VersionFromJSON(json['version']),
        'license': !exists(json, 'license') ? undefined : json['license'],
    };
}

export function SystemInfoToJSON(value?: SystemInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': VersionToJSON(value.version),
        'license': value.license,
    };
}


