import * as ActionTypes from '../constants/actionTypes';
import { RepositorySpecificInfo } from '../generated/client';

/**
 * Interface describing predefined element
 */
export interface PredefinedElement {
  name: string,
  value: string
}

/**
 * Interface for set repository info action type
 */
export interface SetRepositoryInfoAction {
  type: ActionTypes.SET_REPOSITORY_INFO;
  repositoryInfo: RepositorySpecificInfo;
}

/**
 * Interface for set predefined context elements action type
 */
export interface SetPredefinedElementsAction {
  type: ActionTypes.SET_PREDEFINED_ELEMENTS,
  predefinedElements: PredefinedElement[]
}

/**
 * Store method for set repository info
 *
 * @param repositoryInfo repository info
 */
export const setRepositoryInfo = (repositoryInfo: RepositorySpecificInfo): SetRepositoryInfoAction => {
  return {
    type: ActionTypes.SET_REPOSITORY_INFO,
    repositoryInfo
  };
}

/**
 * Store method for set predefined context elements
 *
 * @param predefinedElements predefined elements
 */
export const setPredefinedElements = (predefinedElements: PredefinedElement[]): SetPredefinedElementsAction => {
  return {
    type: ActionTypes.SET_PREDEFINED_ELEMENTS,
    predefinedElements
  };
}

export type RepositoryAction = SetRepositoryInfoAction | SetPredefinedElementsAction;