/* tslint:disable */
/* eslint-disable */
/**
 * ConfigHub API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RepositoryFile,
    RepositoryFileFromJSON,
    RepositoryFileFromJSONTyped,
    RepositoryFileToJSON,
} from './';

/**
 * 
 * @export
 * @interface RepositorySpecificInfo
 */
export interface RepositorySpecificInfo {
    /**
     * 
     * @type {string}
     * @memberof RepositorySpecificInfo
     */
    account?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositorySpecificInfo
     */
    repository?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositorySpecificInfo
     */
    generatedOn?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepositorySpecificInfo
     */
    context?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof RepositorySpecificInfo
     */
    contextElements?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<RepositoryFile>}
     * @memberof RepositorySpecificInfo
     */
    files?: Array<RepositoryFile>;
}

export function RepositorySpecificInfoFromJSON(json: any): RepositorySpecificInfo {
    return RepositorySpecificInfoFromJSONTyped(json, false);
}

export function RepositorySpecificInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepositorySpecificInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account': !exists(json, 'account') ? undefined : json['account'],
        'repository': !exists(json, 'repository') ? undefined : json['repository'],
        'generatedOn': !exists(json, 'generatedOn') ? undefined : json['generatedOn'],
        'context': !exists(json, 'context') ? undefined : json['context'],
        'contextElements': !exists(json, 'contextElements') ? undefined : json['contextElements'],
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(RepositoryFileFromJSON)),
    };
}

export function RepositorySpecificInfoToJSON(value?: RepositorySpecificInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account': value.account,
        'repository': value.repository,
        'generatedOn': value.generatedOn,
        'context': value.context,
        'contextElements': value.contextElements,
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(RepositoryFileToJSON)),
    };
}


