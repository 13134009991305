import * as React from "react";
import { withStyles, WithStyles, CircularProgress } from "@material-ui/core";
import { styles } from "../../styles/generic/image-uploader";
import { DropzoneArea } from "material-ui-dropzone";
import * as ImageUpload from "../../utils/image-upload";
import GenericAlert from "./generic-alert";
import strings from "../../localization/strings";

/**
 * Component props
 */
interface Props extends WithStyles<typeof styles> {
  imageUrls?: string[];
  onImagesUploaded: (imageUrls: string[]) => void;
}

/**
 * Component states
 */
interface State {
  error: boolean;
  uploading: boolean;
  alertMessage?: string;
}

/**
 * Image uploader component
 */
class ImageUploader extends React.Component<Props, State> {
  /**
   * Constructor
   *
   * @param props component properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      error: false,
      uploading: false
    };
  }

  /**
   * Component render method
   */
  public render = () => {
    const { classes } = this.props;
    const { error, alertMessage } = this.state;

    if (this.state.uploading) {
      return (
        <div className={ classes.root }>
          <CircularProgress color="secondary" style={{ alignSelf: "center" }} />
        </div>
      );
    }

    return (
      <div className={ classes.root }>
        <DropzoneArea
          classes={{
            root: classes.dropzone,
            textContainer: classes.dropzoneTextContainer
          }}
          onDrop={ files => this.uploadImages(files) }
          acceptedFiles={[ "" ]}
          filesLimit={ 10 }
          maxFileSize={ 200000000 }
          showPreviewsInDropzone={ false }
          dropzoneText={ strings.dragImagesOrClick }
          showAlerts={ false }
          showPreviews={ false }
          showFileNamesInPreview={ false }
          showFileNames={ false }
        />
        <GenericAlert
          error={ error }
          message={ alertMessage }
          onClose={ this.hideAlert }
        />
      </div>
    );
  }

  /**
   * Uploads images to AWS S3
   *
   * @param files list of files to upload
   */
  private uploadImages = async (files: File[]) => {
    const { imageUrls, onImagesUploaded } = this.props;
    const customer = process.env.REACT_APP_CUSTOMER_NAME;
    if (!customer) {
      this.showAlert(strings.errorInUploading, true);
      console.error("Customer name not found from environment variables");
    }

    const newImages: string[] = [];
    for (const file of files) {
      try {
        const res = await ImageUpload.getPresignedPostData(file, customer!);
        await ImageUpload.uploadFileToS3(res.data, file);
        const newImageUrl = `${res.basePath}/${res.data.fields.key}`;
        newImages.push(newImageUrl);
      } catch (e) {
        console.error(e);
        this.showAlert(strings.errorInUploading, true);
      }
    }

    this.showAlert(strings.successfulUpload, false);
    const previousImages = imageUrls ?? [];
    onImagesUploaded && onImagesUploaded(previousImages.concat(newImages));
  }

  /**
   * Shows alert
   * 
   * @param alertMessage message of the alert
   * @param error if message is error
   */
  private showAlert = (alertMessage: string, error: boolean) => {
    this.setState({
      alertMessage,
      error
    });
  }

  /**
   * Hides alert
   */
  private hideAlert = () => {
    this.setState({
      error: false,
      alertMessage: undefined
    });
  }
}

export default withStyles(styles)(ImageUploader);
