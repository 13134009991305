import * as React from "react";
import { withStyles, WithStyles } from "@material-ui/core";
import { styles } from "../../styles/generic/property-list";
import { Property } from "../../types";
import PropertyCard from "./property-card";
import { Skeleton } from "@material-ui/lab";
import PropertyUtils from "../../utils/property-utils";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  loading?: boolean;
  currentContextLevel: number;
  properties: Property[];
  currentlyEditing?: Property;
  onStartEdit: (property: Property) => void;
  onCancelEdit: () => void;
  onUpdate: (property: Property, clear?: boolean) => void;
}

/**
 * Property list component
 *
 * @param props component props
 */
const PropertyList: React.FC<Props> = ({
  classes,
  loading,
  currentContextLevel,
  onUpdate,
  properties,
  currentlyEditing,
  onStartEdit,
  onCancelEdit
}) => {

  /**
   * Renders property
   * 
   * @param property property to render
   */
  const renderProperty = (property: Property) => {
    if (property.name.endsWith("_options")) {
      return null;
    }

    if (property.name.endsWith("_type")) {
      const propertyOptions = PropertyUtils.findOptionsForProperty(property.name, properties);

      if (propertyOptions) {
        return (
          <PropertyCard
            key={ property.name }
            currentContextLevel={ currentContextLevel }
            property={ property }
            propertyOptions={ propertyOptions }
            currentlyEditing={ currentlyEditing }
            onStartEdit={ onStartEdit }
            onCancelEdit={ onCancelEdit }
            onUpdate={ onUpdate }
          />
        );
      }
    }

    return (
      <PropertyCard
        key={ property.name }
        currentContextLevel={ currentContextLevel }
        property={ property }
        currentlyEditing={ currentlyEditing }
        onStartEdit={ onStartEdit }
        onCancelEdit={ onCancelEdit }
        onUpdate={ onUpdate }
      />
    );
  }

  /**
   * Renders placeholders while loading properties
   */
  const renderPlaceHolders = () => {
    return Array.from(Array(10)).map((value, index) =>
      <Skeleton
        key={ index }
        variant="rect"
        animation="wave"
        className={ classes.skeleton }
      />
    );
  }

  /**
   * Component render
   */
  return (
    <div className={ classes.root }>
      { loading ? 
        renderPlaceHolders() :
        properties.map(renderProperty)
      }
    </div>
  );
}

export default withStyles(styles)(PropertyList);