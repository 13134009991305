import * as React from "react";
import { Snackbar, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "../../styles/generic/string-list";
import MuiAlert from '@material-ui/lab/Alert';

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  message?: string;
  error?: boolean;
  onClose: () => void;
}

/**
 * Generic alert component
 *
 * @param props component properties
 */
const GenericAlert: React.FC<Props> = ({
  classes,
  message,
  error,
  onClose
}) => {

  /**
   * Component render
   */
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={ !!message }
      autoHideDuration={ 6000 }
      onClose={ onClose }
      onExited={ onClose }
      className={ classes.root }
    >
      <MuiAlert
        onClose={ onClose }
        severity={ !error ? "success" : "error" }
        variant="filled"
        elevation={ 6 }
        style={{ border: "solid 1px black" }}
      >
        { message }
      </MuiAlert>
    </Snackbar>
  );
}

export default withStyles(styles)(GenericAlert);
