import { createStyles } from "@material-ui/core";
import theme from "../../theme/theme";

export const styles = createStyles({

  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },

  listRow: {
    width: "100%",
    marginBottom: 10,
    display: "flex"
  },

  textField: {
    flexGrow: 1
  },

  addButton: {
    fontSize: 40
  },

  deleteButton: {
    fontSize: 40,
    color: theme.palette.error.main
  }

});
