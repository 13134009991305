/* tslint:disable */
/* eslint-disable */
/**
 * ConfigHub API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PulledFile
 */
export interface PulledFile {
    /**
     * 
     * @type {Blob}
     * @memberof PulledFile
     */
    content?: Blob;
    /**
     * 
     * @type {string}
     * @memberof PulledFile
     */
    contentType?: string;
}

export function PulledFileFromJSON(json: any): PulledFile {
    return PulledFileFromJSONTyped(json, false);
}

export function PulledFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): PulledFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : json['content'],
        'contentType': !exists(json, 'content-type') ? undefined : json['content-type'],
    };
}

export function PulledFileToJSON(value?: PulledFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'content-type': value.contentType,
    };
}


