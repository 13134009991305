/**
 * Generic utils
 */
export default class GenericUtils {
  /**
   * Returns application name
   */
  public static getApplicationName = () => {
    return process.env.REACT_APP_CONFIG_HUB_APP_NAME || "config-management";
  }
}