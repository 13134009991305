import { Configuration, FileApi, InfoApi, ConfigApi } from "../generated/client";

/**
 * Utility class for loading api with predefined configuration
 */
export default class Api {

  /**
   * Gets initialized info api
   */
  public static getInfoApi() {
    return new InfoApi(Api.getConfiguration());
  }

  /**
   * Gets initialized file api
   */
  public static getFileApi() {
    return new FileApi(Api.getConfiguration());
  }

  /**
   * Gets initialized config api
   */
  public static getConfigApi() {
    return new ConfigApi(Api.getConfiguration());
  }

  /**
   * Gets api configuration
   *
   * @param token access token
   */
  private static getConfiguration() {
    return new Configuration({
      basePath: process.env.REACT_APP_CONFIG_HUB_BASE_PATH,
      headers: {
        "Client-Token": `${ process.env.REACT_APP_CONFIG_HUB_TOKEN }`
      }
    });
  }

}
