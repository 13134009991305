/* tslint:disable */
/* eslint-disable */
/**
 * ConfigHub API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PulledConfigData,
    PulledConfigDataFromJSON,
    PulledConfigDataToJSON,
    PushedConfigData,
    PushedConfigDataFromJSON,
    PushedConfigDataToJSON,
} from '../models';

export interface GetConfigRequest {
    applicationName: string;
    context: string;
    clientVersion?: string;
    securityProfileAuth?: string;
    pretty?: boolean;
    repositoryDate?: Date;
    tag?: string;
    includeComments?: boolean;
    includeValueContext?: boolean;
    noProperties?: boolean;
    noFiles?: boolean;
}

export interface UpdateConfigRequest {
    applicationName: string;
    pushedConfigData?: PushedConfigData;
    clientVersion?: string;
}

/**
 * 
 */
export class ConfigApi extends runtime.BaseAPI {

    /**
     * Gets configuration from ConfigHub service with specified context
     * Get config
     */
    async getConfigRaw(requestParameters: GetConfigRequest): Promise<runtime.ApiResponse<PulledConfigData>> {
        if (requestParameters.applicationName === null || requestParameters.applicationName === undefined) {
            throw new runtime.RequiredError('applicationName','Required parameter requestParameters.applicationName was null or undefined when calling getConfig.');
        }

        if (requestParameters.context === null || requestParameters.context === undefined) {
            throw new runtime.RequiredError('context','Required parameter requestParameters.context was null or undefined when calling getConfig.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.clientVersion !== undefined && requestParameters.clientVersion !== null) {
            headerParameters['Client-Version'] = String(requestParameters.clientVersion);
        }

        if (requestParameters.applicationName !== undefined && requestParameters.applicationName !== null) {
            headerParameters['Application-Name'] = String(requestParameters.applicationName);
        }

        if (requestParameters.context !== undefined && requestParameters.context !== null) {
            headerParameters['Context'] = String(requestParameters.context);
        }

        if (requestParameters.securityProfileAuth !== undefined && requestParameters.securityProfileAuth !== null) {
            headerParameters['Security-Profile-Auth'] = String(requestParameters.securityProfileAuth);
        }

        if (requestParameters.pretty !== undefined && requestParameters.pretty !== null) {
            headerParameters['Pretty'] = String(requestParameters.pretty);
        }

        if (requestParameters.repositoryDate !== undefined && requestParameters.repositoryDate !== null) {
            headerParameters['Repository-Date'] = String(requestParameters.repositoryDate);
        }

        if (requestParameters.tag !== undefined && requestParameters.tag !== null) {
            headerParameters['Tag'] = String(requestParameters.tag);
        }

        if (requestParameters.includeComments !== undefined && requestParameters.includeComments !== null) {
            headerParameters['Include-Comments'] = String(requestParameters.includeComments);
        }

        if (requestParameters.includeValueContext !== undefined && requestParameters.includeValueContext !== null) {
            headerParameters['Include-Value-Context'] = String(requestParameters.includeValueContext);
        }

        if (requestParameters.noProperties !== undefined && requestParameters.noProperties !== null) {
            headerParameters['No-Properties'] = String(requestParameters.noProperties);
        }

        if (requestParameters.noFiles !== undefined && requestParameters.noFiles !== null) {
            headerParameters['No-Files'] = String(requestParameters.noFiles);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pull`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PulledConfigDataFromJSON(jsonValue));
    }

    /**
     * Gets configuration from ConfigHub service with specified context
     * Get config
     */
    async getConfig(requestParameters: GetConfigRequest): Promise<PulledConfigData> {
        const response = await this.getConfigRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates new or modified config(s) and/or file(s) to ConfigHub service
     * Update config
     */
    async updateConfigRaw(requestParameters: UpdateConfigRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.applicationName === null || requestParameters.applicationName === undefined) {
            throw new runtime.RequiredError('applicationName','Required parameter requestParameters.applicationName was null or undefined when calling updateConfig.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.clientVersion !== undefined && requestParameters.clientVersion !== null) {
            headerParameters['Client-Version'] = String(requestParameters.clientVersion);
        }

        if (requestParameters.applicationName !== undefined && requestParameters.applicationName !== null) {
            headerParameters['Application-Name'] = String(requestParameters.applicationName);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/push`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PushedConfigDataToJSON(requestParameters.pushedConfigData),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates new or modified config(s) and/or file(s) to ConfigHub service
     * Update config
     */
    async updateConfig(requestParameters: UpdateConfigRequest): Promise<void> {
        await this.updateConfigRaw(requestParameters);
    }

}
