/* tslint:disable */
/* eslint-disable */
/**
 * ConfigHub API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PushedPropertyValue
 */
export interface PushedPropertyValue {
    /**
     * 
     * @type {string}
     * @memberof PushedPropertyValue
     */
    context: string;
    /**
     * 
     * @type {string}
     * @memberof PushedPropertyValue
     */
    value: string;
    /**
     * 
     * @type {boolean}
     * @memberof PushedPropertyValue
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof PushedPropertyValue
     */
    opp?: PushedPropertyValueOppEnum;
}

export function PushedPropertyValueFromJSON(json: any): PushedPropertyValue {
    return PushedPropertyValueFromJSONTyped(json, false);
}

export function PushedPropertyValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushedPropertyValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': json['context'],
        'value': json['value'],
        'active': json['active'],
        'opp': !exists(json, 'opp') ? undefined : json['opp'],
    };
}

export function PushedPropertyValueToJSON(value?: PushedPropertyValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': value.context,
        'value': value.value,
        'active': value.active,
        'opp': value.opp,
    };
}

/**
* @export
* @enum {string}
*/
export enum PushedPropertyValueOppEnum {
    Delete = 'delete'
}


