import { createStyles } from "@material-ui/core";
import theme from "../../theme/theme";

export const styles = createStyles({

  root: {},

  appBar: {},

  toolBar: {
    height: 100,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  },

  contextField: {
    flexGrow: 1,
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: theme.palette.primary.main
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.primary.main}`
    }
  },

  contextFieldInput: {
    borderColor: theme.palette.primary.main
  },

  arrowIcon: {
    fontSize: 40,
    margin: "0 20px"
  }

});
