import { createStyles } from "@material-ui/core";
import theme from "../../theme/theme";

export const styles = createStyles({

  root: {
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  dropzone: {
    width: "100%",
    height: "100%",
    minHeight: 150,
    borderColor: theme.palette.primary.main,
    borderWidth: 1
  },

  dropzoneTextContainer: {
    fontSize: 20,
    "& svg": {
      color: theme.palette.primary.main
    }
  },

  uploadIcon: {
    fontSize: 10
  }

});