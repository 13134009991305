  /**
   * Retrieve pre-signed POST data from a dedicated API endpoint.
   *
   * @param selectedFile selected file
   * @param userId user id
   * @returns {Promise<any>} promise of pre-signed post data
   */
  export const getPresignedPostData = (selectedFile: File, userId: string): Promise<any> => {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();
      const url = process.env.REACT_APP_USER_CONTENT_UPLOAD_URL;

      if (!url) {
        console.error("Content upload url not found from environment");
        return;
      }

      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(
        JSON.stringify({
          userId: userId,
          name: selectedFile.name,
          type: selectedFile.type
        })
      );
      xhr.onload = function() {
        resolve(JSON.parse(this.responseText));
      };
    });
  };

  /**
   * Upload file to S3 with previously received pre-signed POST data.
   *
   * @param presignedPostData pre-signed post data
   * @param file file
   * @returns {Promise<any>} promise of successful upload
   */
  export const uploadFileToS3 = (presignedPostData: any, file: File) => {
    return new Promise<void>((resolve, reject) => {
      const formData = new FormData();
      Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key]);
      });

      formData.append("file", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", presignedPostData.url, true);
      xhr.send(formData);
      xhr.onload = function() {
        this.status === 204 ? resolve() : reject(this.responseText);
      };
    });
  };
