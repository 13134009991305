/* tslint:disable */
/* eslint-disable */
/**
 * ConfigHub API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PropertyType,
    PropertyTypeFromJSON,
    PropertyTypeFromJSONTyped,
    PropertyTypeToJSON,
    PushedPropertyValue,
    PushedPropertyValueFromJSON,
    PushedPropertyValueFromJSONTyped,
    PushedPropertyValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface PushedProperty
 */
export interface PushedProperty {
    /**
     * 
     * @type {string}
     * @memberof PushedProperty
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof PushedProperty
     */
    readme?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PushedProperty
     */
    deprecated?: boolean;
    /**
     * 
     * @type {PropertyType}
     * @memberof PushedProperty
     */
    vdt: PropertyType;
    /**
     * 
     * @type {boolean}
     * @memberof PushedProperty
     */
    push: boolean;
    /**
     * 
     * @type {string}
     * @memberof PushedProperty
     */
    securityGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof PushedProperty
     */
    password?: string;
    /**
     * 
     * @type {Array<PushedPropertyValue>}
     * @memberof PushedProperty
     */
    values: Array<PushedPropertyValue>;
}

export function PushedPropertyFromJSON(json: any): PushedProperty {
    return PushedPropertyFromJSONTyped(json, false);
}

export function PushedPropertyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushedProperty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'readme': !exists(json, 'readme') ? undefined : json['readme'],
        'deprecated': !exists(json, 'deprecated') ? undefined : json['deprecated'],
        'vdt': PropertyTypeFromJSON(json['vdt']),
        'push': json['push'],
        'securityGroup': !exists(json, 'securityGroup') ? undefined : json['securityGroup'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'values': ((json['values'] as Array<any>).map(PushedPropertyValueFromJSON)),
    };
}

export function PushedPropertyToJSON(value?: PushedProperty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'readme': value.readme,
        'deprecated': value.deprecated,
        'vdt': PropertyTypeToJSON(value.vdt),
        'push': value.push,
        'securityGroup': value.securityGroup,
        'password': value.password,
        'values': ((value.values as Array<any>).map(PushedPropertyValueToJSON)),
    };
}


