import * as React from "react";
import { GridList, GridListTile, GridListTileBar, IconButton, Typography, useTheme, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "../../styles/generic/image-list";
import strings from "../../localization/strings";
import produce from "immer";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import useMediaQuery from '@material-ui/core/useMediaQuery';

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  images: string[];
  editable?: boolean;
  onUpdate?: (updatedList: string[]) => void;
}

/**
 * Image list component
 *
 * @param props component properties
 */
const ImageList: React.FC<Props> = ({ classes, images, editable, onUpdate }) => {

  const theme = useTheme();
  /**
   * TODO:
   * Figure out better way to use hooks
   */
  const belowXs = useMediaQuery(theme.breakpoints.down("xs"));
  const belowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const belowMd = useMediaQuery(theme.breakpoints.down("md"));
  const belowLg = useMediaQuery(theme.breakpoints.down("lg"));
  const belowXl = useMediaQuery(theme.breakpoints.down("xl"));

  /**
   * Resolves grid list column amount
   */
  const resolveColumnAmount = () => {
    if (belowXs) {
      return 1;
    }
    if (belowSm) {
      return 2;
    }
    if (belowMd) {
      return 4;
    }
    if (belowLg) {
      return 6;
    }
    if (belowXl) {
      return 8;
    }
    return 10;
  }

  /**
   * Event handler for remove item from list
   *
   * @param index item index
   */
  const onRemoveItem = (index: number) => () => {
    if (images){
      onUpdate && onUpdate(
        produce(images, draft => {
          draft.splice(index, 1);
        })
      )
    }
  }

  /**
   * Renders list
   * 
   * @param value value
   * @param index item index
   */
  const renderImageTile = (value: string, index: number) => {
    return (
      <GridListTile
        key={ index }
        className={ classes.imageTile }
      >
        <img src={ value } alt={ strings.imageAlt } />
        { editable &&
          <GridListTileBar
            className={ classes.tileBar }
            titlePosition="top"
            actionPosition="right"
            actionIcon={
              <IconButton
                className={ classes.deleteButton }
                onClick={ onRemoveItem(index) }
              >
                <DeleteOutlineIcon/>
              </IconButton>
            }
          />
        }
      </GridListTile>
    );
  }

  /**
   * Component render
   */
  if (images.length < 1) {
    return <Typography>{ strings.noImages }</Typography>;
  }

  return (
    <GridList
      cellHeight={ 200 }
      className={ classes.imageGrid }
      cols={ resolveColumnAmount() }
      spacing={ 20 }
    >
      { images.map(renderImageTile) }
    </GridList>
  );
}

export default withStyles(styles)(ImageList);
