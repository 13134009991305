/* tslint:disable */
/* eslint-disable */
/**
 * ConfigHub API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface GetSingleFileRequest {
    applicationName: string;
    context: string;
    clientVersion?: string;
    securityProfileAuth?: string;
    repositoryDate?: Date;
    tag?: string;
}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI {

    /**
     * With fully specified context, pull a specific file from ConfigHub service. The response contains raw, resolved configuration file.
     * Get single file
     */
    async getSingleFileRaw(requestParameters: GetSingleFileRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.applicationName === null || requestParameters.applicationName === undefined) {
            throw new runtime.RequiredError('applicationName','Required parameter requestParameters.applicationName was null or undefined when calling getSingleFile.');
        }

        if (requestParameters.context === null || requestParameters.context === undefined) {
            throw new runtime.RequiredError('context','Required parameter requestParameters.context was null or undefined when calling getSingleFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.clientVersion !== undefined && requestParameters.clientVersion !== null) {
            headerParameters['Client-Version'] = String(requestParameters.clientVersion);
        }

        if (requestParameters.applicationName !== undefined && requestParameters.applicationName !== null) {
            headerParameters['Application-Name'] = String(requestParameters.applicationName);
        }

        if (requestParameters.context !== undefined && requestParameters.context !== null) {
            headerParameters['Context'] = String(requestParameters.context);
        }

        if (requestParameters.securityProfileAuth !== undefined && requestParameters.securityProfileAuth !== null) {
            headerParameters['Security-Profile-Auth'] = String(requestParameters.securityProfileAuth);
        }

        if (requestParameters.repositoryDate !== undefined && requestParameters.repositoryDate !== null) {
            headerParameters['Repository-Date'] = String(requestParameters.repositoryDate);
        }

        if (requestParameters.tag !== undefined && requestParameters.tag !== null) {
            headerParameters['Tag'] = String(requestParameters.tag);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/rawFile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * With fully specified context, pull a specific file from ConfigHub service. The response contains raw, resolved configuration file.
     * Get single file
     */
    async getSingleFile(requestParameters: GetSingleFileRequest): Promise<Blob> {
        const response = await this.getSingleFileRaw(requestParameters);
        return await response.value();
    }

}
