/* tslint:disable */
/* eslint-disable */
/**
 * ConfigHub API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepositoryOverallInfo
 */
export interface RepositoryOverallInfo {
    /**
     * 
     * @type {string}
     * @memberof RepositoryOverallInfo
     */
    account?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryOverallInfo
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryOverallInfo
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryOverallInfo
     */
    isPersonal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepositoryOverallInfo
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof RepositoryOverallInfo
     */
    created?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryOverallInfo
     */
    accessControlsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryOverallInfo
     */
    vdtEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryOverallInfo
     */
    securityEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepositoryOverallInfo
     */
    contextGroupsEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RepositoryOverallInfo
     */
    keyCount?: number;
    /**
     * 
     * @type {number}
     * @memberof RepositoryOverallInfo
     */
    valueCount?: number;
    /**
     * 
     * @type {number}
     * @memberof RepositoryOverallInfo
     */
    userCount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepositoryOverallInfo
     */
    context?: Array<string>;
}

export function RepositoryOverallInfoFromJSON(json: any): RepositoryOverallInfo {
    return RepositoryOverallInfoFromJSONTyped(json, false);
}

export function RepositoryOverallInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepositoryOverallInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account': !exists(json, 'account') ? undefined : json['account'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isPrivate': !exists(json, 'isPrivate') ? undefined : json['isPrivate'],
        'isPersonal': !exists(json, 'isPersonal') ? undefined : json['isPersonal'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'accessControlsEnabled': !exists(json, 'accessControlsEnabled') ? undefined : json['accessControlsEnabled'],
        'vdtEnabled': !exists(json, 'vdtEnabled') ? undefined : json['vdtEnabled'],
        'securityEnabled': !exists(json, 'securityEnabled') ? undefined : json['securityEnabled'],
        'contextGroupsEnabled': !exists(json, 'contextGroupsEnabled') ? undefined : json['contextGroupsEnabled'],
        'keyCount': !exists(json, 'keyCount') ? undefined : json['keyCount'],
        'valueCount': !exists(json, 'valueCount') ? undefined : json['valueCount'],
        'userCount': !exists(json, 'userCount') ? undefined : json['userCount'],
        'context': !exists(json, 'context') ? undefined : json['context'],
    };
}

export function RepositoryOverallInfoToJSON(value?: RepositoryOverallInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account': value.account,
        'name': value.name,
        'isPrivate': value.isPrivate,
        'isPersonal': value.isPersonal,
        'description': value.description,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'accessControlsEnabled': value.accessControlsEnabled,
        'vdtEnabled': value.vdtEnabled,
        'securityEnabled': value.securityEnabled,
        'contextGroupsEnabled': value.contextGroupsEnabled,
        'keyCount': value.keyCount,
        'valueCount': value.valueCount,
        'userCount': value.userCount,
        'context': value.context,
    };
}


