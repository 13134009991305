import { createStyles } from "@material-ui/core";
import theme from "../../theme/theme";

export const styles = createStyles({

  container: {
    height: "calc(100% - 64px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },

  contextEditor: {
    display: "flex",
    width: "100%",
    alignItems: "center"
  },

  overriddenValues: {
    marginTop: 20,
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between"
  },

  clearButton: {
    padding: "15px 20px",
    letterSpacing: 0.1,
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main
  },

  clearIcon: {
    marginLeft: 15
  },

  propertiesContainer: {
    overflowY: "auto",
    width: "100%"
  },

  snackbar: {
    "& .MuiAlert-message": {
      fontSize: 18
    },
    "& .MuiAlert-icon, .MuiAlert-action": {
      fontSize: 26
    }
  }

});