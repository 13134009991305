import * as React from "react";

import { ReduxActions, ReduxState } from "../../store";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import { PredefinedElement } from "../../actions/repository";

import { Button, Typography, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "../../styles/screens/main-screen";
import { History } from "history";
import AppLayout from "../layouts/app-layout";
import { AccessToken, ContextLevel, Property } from "../../types";
import Api from "../../api/api";
import { RepositorySpecificInfo, PulledConfigData, PushedConfigData, PushedProperty, PropertyType, PushedPropertyValueOppEnum } from "../../generated/client";
import SubHeader from "../generic/sub-header";
import ContextSelect from "../generic/context-select";
import PropertyList from "../generic/property-list";
import ContextUtils from "../../utils/context-utils";
import GenericUtils from "../../utils";
import { KeycloakInstance } from "keycloak-js";
import strings from "../../localization/strings";
import GenericAlert from "../generic/generic-alert";
import PropertyUtils from "../../utils/property-utils";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  history: History<History.LocationState>;
  context: string;
  accessToken?: AccessToken;
  keycloak?: KeycloakInstance;
  repositoryInfo?: RepositorySpecificInfo;
  predefinedElements?: PredefinedElement[];
  logout: typeof logout;
}

/**
 * Interface describing component state
 */
interface State {
  loading: boolean;
  configData?: PulledConfigData;
  properties: Property[];
  urlSlugs: string[];
  fullContext?: string;
  contextLevels: ContextLevel[];
  currentlyEditing?: Property;
  error: boolean;
  snackMessage?: string;
}

/**
 * Component for main screen
 */
class MainScreen extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props props 
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      urlSlugs: [],
      contextLevels: [],
      properties: []
    };
  }

  /**
   * Component did mount life cycle method
   */
  public componentDidMount = async () => {
    this.setState({ loading: true });
    await this.fetchData();
    this.setState({ loading: false });
  }

  /**
   * Component did update life cycle method
   * 
   * @param prevProps previous component props
   */
  public componentDidUpdate = async (prevProps: Props) => {
    if (
      this.props.context !== prevProps.context ||
      this.props.repositoryInfo !== prevProps.repositoryInfo ||
      this.props.predefinedElements !== prevProps.predefinedElements
    ) {
      this.setState({ loading: true });
      await this.fetchData();
      this.setState({ loading: false });
    }
  }

  /**
   * Component render method
   */
  render() {
    const { classes } = this.props;
    const {
      loading,
      properties,
      currentlyEditing,
      snackMessage,
      error
    } = this.state;

    return (
      <AppLayout>
        <div className={ classes.container }>
          <SubHeader>
            { this.renderContextEditor() }
            { this.renderClearOverridingPropertyValues() }
          </SubHeader>
          <div className={ classes.propertiesContainer }>
            <PropertyList
              loading={ loading }
              currentContextLevel={ this.getCurrentContextLevel() }
              properties={ properties }
              currentlyEditing={ currentlyEditing }
              onStartEdit={ this.startEdit }
              onCancelEdit={ this.cancelEdit }
              onUpdate={ this.updateProperty }
            />
            
          </div>
          <GenericAlert
            message={ snackMessage }
            error={ error }
            onClose={ this.hideSnackbar }
          />
        </div>
      </AppLayout>
    );
  }

  /**
   * Renders context selectors
   */
  private renderContextEditor = () => {
    const { classes, predefinedElements } = this.props;
    const { contextLevels, urlSlugs } = this.state;

    if (!predefinedElements) {
      return;
    }

    const modifiableLevels = contextLevels.slice(predefinedElements.length);
    return (
      <div className={ classes.contextEditor }>
        {
          modifiableLevels.map((level, index) =>
            <ContextSelect
              key={ index }
              contextLevel={ level }
              slugIndex={ index }
              onUpdate={ this.changeContext }
              isLast={ index >= modifiableLevels.length - 1 }
              value={ urlSlugs[index] }
              disabled={ urlSlugs.length < index }
            />
          )
        }
      </div>
    );
  }

  /**
   * Renders clear overriding property values 
   */
  private renderClearOverridingPropertyValues = () => {
    const { classes } = this.props;
    const { properties } = this.state;

    const currentContextLevel = this.getCurrentContextLevel();
    const overridingProperties = properties.filter(property =>
      PropertyUtils.isOverriddenInCurrentContext(property, currentContextLevel)
    );
    const overridingPropertyNames = overridingProperties.map(property => property.description ?? property.name);

    if (overridingProperties.length > 0) {
      return (
        <div className={ classes.overriddenValues }>
          <Typography color="error">
            { strings.formatString(strings.contextHasOverridingValues, overridingPropertyNames.join(", ")) }
          </Typography>
          <Button
            className={ classes.clearButton }
            onClick={ () => this.clearAllOverridingProperties(overridingProperties) }
            variant="outlined"
          >
            { strings.clearAllOverridingValues }
            <DeleteOutlineIcon className={ classes.clearIcon }/>
          </Button>
        </div>
      );
    }
  }

  /**
   * Returns current context level
   */
  private getCurrentContextLevel = () => {
    return (this.props.predefinedElements || []).length + this.state.urlSlugs.length - 1;
  }

  /**
   * Fetches component data
   */
  private fetchData = async () => {
    const { history, repositoryInfo, predefinedElements } = this.props;

    if (!repositoryInfo || !predefinedElements) {
      return;
    }

    const urlSlugs = history.location.pathname
      .split("/")
      .filter(value => !!value);

    const contextLength = Object.keys(repositoryInfo.contextElements || []).length;
    const contextLevels = ContextUtils.translateToContextLevels(repositoryInfo.contextElements || {}, repositoryInfo.context || []);

    const fullContext = ContextUtils.createFullContext(predefinedElements, urlSlugs, contextLength);
    const configApi = Api.getConfigApi();
    const configData = await configApi.getConfig({
      context: fullContext,
      applicationName: GenericUtils.getApplicationName(),
      noFiles: true,
      includeComments: true,
      includeValueContext: true
    });
    const properties = ContextUtils.translateToProperties(configData?.properties);

    this.setState({
      configData,
      properties,
      urlSlugs,
      fullContext,
      contextLevels,
      currentlyEditing: undefined
    });
  }

  /**
   * Changes context based on selection
   * 
   * @param level level to change
   * @param event React change event
   */
  private changeContext = (level: number, value?: string) => {
    const { history } = this.props;
    const { urlSlugs } = this.state;
    const newLevel = level >= urlSlugs.length;

    if (newLevel) {
      if (value) {
        const firstElement = history.location.pathname === "/";
        this.changeRoute(firstElement ? value : `${history.location.pathname}/${value}`);
      }
    } else {
      if (value) {
        const newUrl = urlSlugs
          .map((oldValue, index) => level === index ? value : oldValue)
          .slice(0, level + 1)
          .join("/");

        this.changeRoute(`/${newUrl}`);
      } else {
        const newUrl = [ ...urlSlugs ];
        if (level < newUrl.length) {
          newUrl.splice(level);
        }
        this.changeRoute(`/${newUrl.join("/")}`);
      }
    }
  }

  /**
   * Changes route
   * 
   * @param newUrl new url
   */
  private changeRoute = (newUrl: string) => {
    const { history } = this.props;
    this.setState({ currentlyEditing: undefined });
    history.push(newUrl);
  }

  /**
   * Starts property editing
   * 
   * @param property property to start editing
   */
  private startEdit = (property: Property) => {
    this.setState({ currentlyEditing: property });
  }

  /**
   * Cancels property editing
   */
  private cancelEdit = () => {
    this.setState({ currentlyEditing: undefined });
  }

  /**
   * Hides snackbar
   */
  private hideSnackbar = () => {
    this.setState({ snackMessage: undefined, error: false });
  }

  /**
   * Updates property value
   * 
   * @param property property to update
   * @param clear whether value needs to be cleared from current context
   */
  private updateProperty = async (property: Property, clear: boolean = false) => {
    const { keycloak } = this.props;
    this.setState({ loading: true });

    const pushedConfigData: PushedConfigData = {
      changeComment: `${keycloak?.profile?.email} updated value:`,
      enableKeyCreation: false,
      data: [ this.createPushedProperty(property, clear) ]
    };

    const configApi = Api.getConfigApi();
    configApi.updateConfig({
      applicationName: GenericUtils.getApplicationName(),
      pushedConfigData
    }).then(async () => {
      await this.fetchData();
      this.setState({
        loading: false,
        currentlyEditing: undefined,
        snackMessage: strings.successfulUpdate
      });
    }).catch((e) => {
      console.error(e);
      this.setState({
        loading: false,
        currentlyEditing: undefined,
        error: true,
        snackMessage: strings.errorInUpdate
      });
    });
  }

  /**
   * Clears all overriding properties from current context
   * 
   * @param properties properties
   */
  private clearAllOverridingProperties = (properties: Property[]) => {
    const { keycloak, predefinedElements } = this.props;
    const { fullContext } = this.state;

    if (!predefinedElements) {
      return;
    }

    const displayContext = fullContext ?
      fullContext.split(";").slice(predefinedElements.length).join(" - ") :
      "";

    if (!window.confirm(strings.formatString(strings.confirmClearAll, displayContext) as string)) {
      return;
    }

    this.setState({ loading: true });

    const pushedConfigData: PushedConfigData = {
      changeComment: `${keycloak?.profile?.email} cleared all overriding values from context "${fullContext}"`,
      enableKeyCreation: false,
      data: properties.map(property => this.createPushedProperty(property, true))
    };

    const configApi = Api.getConfigApi();
    configApi.updateConfig({
      applicationName: GenericUtils.getApplicationName(),
      pushedConfigData
    }).then(async () => {
      await this.fetchData();
      this.setState({
        loading: false,
        currentlyEditing: undefined,
        snackMessage: strings.successfulClear
      });
    }).catch((e) => {
      console.error(e);
      this.setState({
        loading: false,
        currentlyEditing: undefined,
        error: true,
        snackMessage: strings.errorInClear
      });
    });
  }

  /**
   * Creates pushed property to be pushed to ConfigHub
   * 
   * @param property property
   * @param clear whether value needs to be cleared from current context
   * @returns PushedProperty object
   */
  private createPushedProperty = (property: Property, clear: boolean): PushedProperty => {
    const { fullContext } = this.state;
    return {
      key: property.name,
      vdt: property.type || PropertyType.Text,
      push: true,
      values: [{
        active: true,
        context: fullContext || "",
        value: property.value as string,
        opp: clear ? PushedPropertyValueOppEnum.Delete : undefined
      }]
    };
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  keycloak: state.auth.keycloak,
  repositoryInfo: state.repository.repositoryInfo,
  predefinedElements: state.repository.predefinedElements
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
  logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainScreen));
