import { createStyles } from "@material-ui/core";
import theme from "../../theme/theme";

export const styles = createStyles({

  root: {
  },

  toolBar: {
    justifyContent: "space-between"
  },

  appTitle: {
    fontWeight: 600,
    letterSpacing: 1.25
  },

  accountButton: {
    height: 40,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.common.white,
    textTransform: "uppercase",
    color: theme.palette.common.black,
    fontSize: 20,
    cursor: "pointer"
  }

});
