import * as React from "react";
import { Button, Card, CardContent, MenuItem, Switch, TextField, Typography, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "../../styles/generic/property-card";
import { Property } from "../../types";
import strings from "../../localization/strings";
import EditIcon from '@material-ui/icons/Edit';
import { PropertyType } from "../../generated/client";
import StringList from "./string-list";
import ImageList from "./image-list";
import ImageUploader from "./image-uploader";
import PropertyUtils from "../../utils/property-utils";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  currentContextLevel: number;
  property: Property;
  propertyOptions?: string[];
  currentlyEditing?: Property;
  onStartEdit: (property: Property) => void;
  onCancelEdit: () => void;
  onUpdate: (property: Property, clear?: boolean) => void;
}

/**
 * Property card component
 *
 * @param props component props
 */
const PropertyCard: React.FC<Props> = props => {
  const {
    classes,
    currentContextLevel,
    property,
    propertyOptions,
    currentlyEditing,
    onStartEdit,
    onCancelEdit,
    onUpdate
  } = props;

  const isEdited = currentlyEditing?.name === property.name;
  const displayName = property.description ?? property.name;

  const [ modifiedValue, setModifiedValue ] = React.useState<any>(property.value as any);

  /**
   * Event handler for start editing
   */
  const onEdit = () => {
    switch (property.type) {
      case PropertyType.Integer:
        const numberValue = Number(property.value as string);
        setModifiedValue(!Number.isNaN(numberValue) ? numberValue : 0);
      break;
      case PropertyType.List:
        const filteredList = filterEmptyValues(property.value ? property.value as string[] : []);
        setModifiedValue(JSON.stringify(filteredList));
      break;
      case PropertyType.Text:
      case PropertyType.Boolean:
      default:
        setModifiedValue(property.value);
      break;
    }

    onStartEdit(property);
  }

  /**
   * On value change
   * 
   * @param event React change event
   */
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModifiedValue(
      property.type === PropertyType.Boolean ?
        `${event.target.checked}` :
        `${event.target.value}`
    );
  }

  /**
   * On update string list
   * 
   * @param updatedList updated list
   */
  const onUpdateStringList = (updatedList: string[]) => {
    const filteredList = filterEmptyValues(updatedList);
    setModifiedValue(JSON.stringify(filteredList));
  }

  /**
   * On update property
   */
  const onUpdateProperty = () => {
    onUpdate({ ...property, value: modifiedValue });
  }

  /**
   * On clear property
   */
  const onClearProperty = () => {
    if (window.confirm(strings.formatString(strings.confirmClear, displayName) as string)) {
      onUpdate(property, true);
    }
  }

  /**
   * Returns if property value is list of image urls
   */
  const isImageList = () => {
    return property.name.includes("image");
  }

  /**
   * Filters empty values from list
   */
  const filterEmptyValues = (list: string[]) => {
    return list.filter(value => !!value);
  }

  /**
   * Renders edit content
   */
  const renderEditContent = () => {
    return (
      <>
        { renderEditableValue() }
        <Button
          className={ classes.cancelButton }
          color="primary"
          onClick={ () => onCancelEdit() }
          variant="outlined"
        >
          { strings.cancel }
        </Button>
        <Button
          className={ classes.saveButton }
          color="primary"
          onClick={ () => onUpdateProperty() }
          variant="contained"
        >
          { strings.save }
        </Button>
      </>
    );
  }

  /**
   * Renders editable value
   */
  const renderEditableValue = () => {
    switch (property.type) {
      case PropertyType.Boolean:
        return renderEditableBoolean();
      case PropertyType.Integer:
        return renderEditableInteger();
      case PropertyType.List:
        return isImageList() ?
          renderEditableImageList() :
          renderEditableStringList();
      case PropertyType.Text:
      default:
        return propertyOptions ?
          renderEditableSelect(propertyOptions) :
          renderEditableString();
    }
  }

  /**
   * Renders editable boolean
   */
  const renderEditableBoolean = () => {
    return (
      <>
        <Switch
          color="primary"
          checked={ modifiedValue as string === "true" }
          onChange={ onChange }
          className={ classes.switch }
        />
        <Typography className={ classes.switchLabel }>
          { displayName }
        </Typography>
      </>
    );
  }

  /**
   * Renders editable integer
   */
  const renderEditableInteger = () => {
    return (
      <TextField
        size="medium"
        type="number"
        variant="outlined"
        className={ classes.textField }
        label={ strings.value }
        value={ modifiedValue as number || 0 }
        InputProps={{ inputProps: { min: 0 } }}
        InputLabelProps={{ variant: "outlined" }}
        onChange={ onChange }
      />
    );
  }

  /**
   * Renders editable image list
   */
  const renderEditableImageList = () => {
    return (
      <div className={ classes.editImagesContainer }>
        <div className={ classes.imageListContainer }>
          <ImageList
            editable
            images={ JSON.parse(modifiedValue) as string[] }
            onUpdate={ onUpdateStringList }
          />
        </div>
        <div className={ classes.imageUploadContainer }>
          <ImageUploader
            imageUrls={ JSON.parse(modifiedValue) as string[] }
            onImagesUploaded={ onUpdateStringList }
          />
        </div>
      </div>
    );
  }

  /**
   * Renders editable string list
   */
  const renderEditableStringList = () => {
    return (
      <StringList
        list={ JSON.parse(modifiedValue) as string[] }
        onUpdate={ onUpdateStringList }
      />
    );
  }

  /**
   * Renders editable select
   * 
   * @param propertyOptions property options
   */
  const renderEditableSelect = (propertyOptions: string[]) => {
    return (
      <TextField
        select
        size="medium"
        variant="outlined"
        className={ classes.textField }
        label={ strings.value }
        value={ modifiedValue as string || "" }
        InputLabelProps={{ variant: "outlined" }}
        onChange={ onChange }
      >
        { propertyOptions.map((option, index) =>
          <MenuItem key={ index } value={ option }>
            { option }
          </MenuItem>)
        }
      </TextField>
    );
  }

  /**
   * Renders editable string
   */
  const renderEditableString = () => {
    return (
      <TextField
        size="medium"
        variant="outlined"
        multiline
        className={ classes.textField }
        label={ strings.value }
        value={ modifiedValue as string || "" }
        InputLabelProps={{ variant: "outlined" }}
        onChange={ onChange }
      />
    );
  }

  /**
   * Renders default content
   */
  const renderDefaultContent = () => {
    return (
      <>
        <div className={ classes.titleArea }>
          <Typography
            variant="h5"
            className={ classes.keyText }
          >
            { displayName }
          </Typography>
          { property.value && renderDefaultValue() }
        </div>
        { PropertyUtils.isOverriddenInCurrentContext(property, currentContextLevel) &&
          <Button
            className={ classes.clearButton }
            onClick={ onClearProperty }
            variant="outlined"
          >
            { strings.clearOverridingValue }
            <DeleteOutlineIcon className={ classes.buttonIcon }/>
          </Button>
        }
        <Button
          className={ classes.editButton }
          disabled={ currentlyEditing && currentlyEditing.name !== property.name }
          color="primary"
          onClick={ onEdit }
          variant="outlined"
        >
          { strings.edit }
          <EditIcon className={ classes.buttonIcon }/>
        </Button>
      </>
    );
  }

  /**
   * Renders default value
   */
  const renderDefaultValue = () => {
    switch (property.type) {
      case PropertyType.Boolean:
        return renderDisplayBoolean();
      case PropertyType.List:
        return isImageList() ?
          renderDisplayImageList() :
          renderDisplayStringList();
      default:
        return renderDisplayString();
    }
  }

  /**
   * Renders display boolean
   */
  const renderDisplayBoolean = () => {
    return (
      <Typography className={ classes.valueText }>
        { strings.getString(property.value as string) }
      </Typography>
    );
  }

  /**
   * Renders display image list
   */
  const renderDisplayImageList = () => {
    const filteredImages = filterEmptyValues(property.value as string[]);

    return (
      <div className={ classes.imageListContainer }>
        <ImageList images={ filteredImages }/>
      </div>
    );
  }

  /**
   * Renders display string list
   */
  const renderDisplayStringList = () => {
    const filteredValues = filterEmptyValues(property.value as string[]);

    return filteredValues.map(item => (
      <Typography key={ item } className={ classes.valueText }>
        { item }
      </Typography>
    ));
  }

  /**
   * Renders display string
   */
  const renderDisplayString = () => {
    return (
      <Typography className={ classes.valueText }>
        { property.value }
      </Typography>
    );
  }

  /**
   * Component render
   */
  return (
    <Card
      elevation={ 2 }
      className={ classes.root }
    >
      <CardContent className={ classes.cardContent }>
        { isEdited ?
          renderEditContent() :
          renderDefaultContent()
        }
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(PropertyCard);