import * as React from 'react';

import { Provider } from "react-redux";
import { createStore } from 'redux';
import { ReduxActions, ReduxState, rootReducer } from "../store";

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { responsiveFontSizes, ThemeProvider, CssBaseline } from '@material-ui/core';
import etraTheme from "../theme/theme";
import moment from "moment";
import "moment/locale/fi";
import "moment/locale/en-gb";
import strings from "../localization/strings";
import AccessTokenRefresh from "./containers/access-token-refresh";
import MainScreen from './screens/main-screen';
import StoreInitializer from './containers/store-initializer';
import { enableAllPlugins } from "immer";


/**
 * Initialize Redux store
 */
const store = createStore<ReduxState, ReduxActions, any, any>(rootReducer);

/**
 * Material UI automated responsive font sizes
 */
const theme = responsiveFontSizes(etraTheme);

/**
 * Interface describing component properties
 */
interface Props {}

/**
 * Interface describing component state
 */
interface State {}

/**
 * App component
 */
class App extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Component did mount life cycle method
   */
  componentDidMount() {
    moment.locale(strings.getLanguage());
    /**
     * Immer initialization
     */
    enableAllPlugins();
  }

  render() {
    return (
      <ThemeProvider theme={ theme }>
        <CssBaseline />
        <Provider store={ store }>
          <AccessTokenRefresh>
            <StoreInitializer>
              <BrowserRouter>
                <Switch>
                  <Route
                    key="main"
                    path="/"
                    render={({ history }) => (
                      <MainScreen
                        history={ history }
                        context={ history.location.pathname }
                      />
                    )}
                  />
                </Switch>
              </BrowserRouter>
            </StoreInitializer>
          </AccessTokenRefresh>
        </Provider>
      </ThemeProvider>
    );
  }

}

export default App;