import { PredefinedElement, RepositoryAction } from '../actions/repository';
import { SET_PREDEFINED_ELEMENTS, SET_REPOSITORY_INFO } from '../constants/actionTypes';
import { Reducer } from "redux";
import { RepositorySpecificInfo } from '../generated/client';

/**
 * Repository state
 */
interface RepositoryState {
  repositoryInfo?: RepositorySpecificInfo;
  predefinedElements: PredefinedElement[] | undefined;
}

/**
 * Initial repository state
 */
const initialState: RepositoryState = {
  repositoryInfo: undefined,
  predefinedElements: undefined
}

/**
 * Redux reducer for repository
 *
 * @param state store state of repository
 * @param action action of repository
 */
export const repositoryReducer: Reducer<RepositoryState, RepositoryAction> = (state = initialState, action): RepositoryState => {
  switch (action.type) {
    case SET_REPOSITORY_INFO:
      return {
        ...state,
        repositoryInfo: action.repositoryInfo
      };
    case SET_PREDEFINED_ELEMENTS:
      return {
        ...state,
        predefinedElements: action.predefinedElements
      }
    default:
      return state;
  }
}