import * as React from "react";
import { TextField, withStyles, WithStyles } from "@material-ui/core";
import Autocomplete, { AutocompleteInputChangeReason, createFilterOptions } from "@material-ui/lab/Autocomplete";
import { FilterOptionsState } from "@material-ui/lab/useAutocomplete";
import { styles } from "../../styles/generic/context-select";
import { ContextLevel } from "../../types";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import theme from "../../theme/theme";

/**
 * Filtering function for Material UI Autocomplete
 */
const filter = createFilterOptions<string>();

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  contextLevel: ContextLevel;
  slugIndex: number;
  value?: string;
  onUpdate: (level: number, value?: string) => void;
  disabled?: boolean;
  isLast?: boolean;
}

/**
 * Context select component
 *
 * @param props component props
 */
const ContextSelect: React.FC<Props> = ({
  classes,
  contextLevel,
  slugIndex,
  value,
  onUpdate,
  disabled,
  isLast
}) => {
  const { name, options } = contextLevel;
  const selected = contextLevel.options.includes(value || "");

  const [ inputValue, setInputValue ] = React.useState<string>("");

  /**
   * Event handler for autocomplete input change
   * 
   * @param event React change event
   * @param value value
   * @param reason event reason
   */
  const onInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === "clear") {
      onUpdate(slugIndex, undefined);
    }

    setInputValue(value);
  }

  /**
   * Event handler for selected value change
   * 
   * @param event React change event
   * @param newValue new value
   */
  const onChange = (event: React.ChangeEvent<{}>, newValue: string | null) => {
    const value = newValue !== null ? newValue : undefined;
    onUpdate(slugIndex, value);
    setInputValue(value || "");
  }

  /**
   * Filters options for autocomplete input
   */
  const filterOptions = (options: string[], params: FilterOptionsState<string>) => {
    const filtered = filter(options, params);

    if (params.inputValue !== "" && !options.find(option => option === params.inputValue)) {
      filtered.push(params.inputValue);
    }

    return filtered;
  }

  /**
   * Component render
   */
  return (
    <>
      <Autocomplete
        freeSolo
        selectOnFocus
        clearOnBlur
        disabled={ disabled }
        inputValue={ inputValue }
        value={ value || "" }
        options={ options }
        filterOptions={ filterOptions }
        onInputChange={ onInputChange }
        onChange={ onChange }
        className={ classes.contextField }
        renderInput={ params => (
          <TextField
            { ...params }
            label={ name }
            variant="outlined"
          />
        )}
      />
      { !isLast &&
        <ArrowRightAltIcon
        className={ classes.arrowIcon }
          style={{
            color: selected ?
              theme.palette.primary.main :
              theme.palette.text.disabled
          }}
        />
      }
    </>
  );
}

export default withStyles(styles)(ContextSelect);