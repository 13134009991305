/* tslint:disable */
/* eslint-disable */
/**
 * ConfigHub API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PropertyType,
    PropertyTypeFromJSON,
    PropertyTypeFromJSONTyped,
    PropertyTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface PulledProperty
 */
export interface PulledProperty {
    /**
     * 
     * @type {string}
     * @memberof PulledProperty
     */
    val: string;
    /**
     * 
     * @type {PropertyType}
     * @memberof PulledProperty
     */
    type?: PropertyType;
    /**
     * 
     * @type {Array<string>}
     * @memberof PulledProperty
     */
    context?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PulledProperty
     */
    comment?: string;
}

export function PulledPropertyFromJSON(json: any): PulledProperty {
    return PulledPropertyFromJSONTyped(json, false);
}

export function PulledPropertyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PulledProperty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'val': json['val'],
        'type': !exists(json, 'type') ? undefined : PropertyTypeFromJSON(json['type']),
        'context': !exists(json, 'context') ? undefined : json['context'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function PulledPropertyToJSON(value?: PulledProperty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'val': value.val,
        'type': PropertyTypeToJSON(value.type),
        'context': value.context,
        'comment': value.comment,
    };
}


