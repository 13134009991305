import { Property } from "../types";

/**
 * Utility class for properties
 */
export default class PropertyUtils {

  /**
   * Finds possible property options for property
   * Name of property itself needs to end with "_type".
   * Name of property containing the options needs to end with "_options".
   * 
   * @param propertyName property name
   * @param properties all properties
   * @returns property options if found, otherwise undefined
   */
  public static findOptionsForProperty = (propertyName: string, properties: Property[]): string[] | undefined => {
    const optionsSelector = propertyName.replace("_type", "_options");
    const options = properties.find(property => property.name === optionsSelector)?.value;
    return options && options.length ? options as string[] : undefined;
  }

  /**
   * Returns whether property value is overridden in current context or not
   * 
   * @param property property
   * @param currentContextLevel current context level
   */
  public static isOverriddenInCurrentContext = (property: Property, currentContextLevel: number) => {
    return (
      currentContextLevel > 0 &&
      property.context &&
      property.context.length > currentContextLevel &&
      property.context[currentContextLevel] !== "*"
    );
  }

}