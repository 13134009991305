/* tslint:disable */
/* eslint-disable */
/**
 * ConfigHub API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PulledFile,
    PulledFileFromJSON,
    PulledFileFromJSONTyped,
    PulledFileToJSON,
    PulledProperty,
    PulledPropertyFromJSON,
    PulledPropertyFromJSONTyped,
    PulledPropertyToJSON,
} from './';

/**
 * 
 * @export
 * @interface PulledConfigData
 */
export interface PulledConfigData {
    /**
     * 
     * @type {string}
     * @memberof PulledConfigData
     */
    generatedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof PulledConfigData
     */
    account?: string;
    /**
     * 
     * @type {string}
     * @memberof PulledConfigData
     */
    repo?: string;
    /**
     * 
     * @type {string}
     * @memberof PulledConfigData
     */
    context?: string;
    /**
     * 
     * @type {{ [key: string]: PulledFile; }}
     * @memberof PulledConfigData
     */
    files?: { [key: string]: PulledFile; };
    /**
     * 
     * @type {{ [key: string]: PulledProperty; }}
     * @memberof PulledConfigData
     */
    properties?: { [key: string]: PulledProperty; };
}

export function PulledConfigDataFromJSON(json: any): PulledConfigData {
    return PulledConfigDataFromJSONTyped(json, false);
}

export function PulledConfigDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PulledConfigData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'generatedOn': !exists(json, 'generatedOn') ? undefined : json['generatedOn'],
        'account': !exists(json, 'account') ? undefined : json['account'],
        'repo': !exists(json, 'repo') ? undefined : json['repo'],
        'context': !exists(json, 'context') ? undefined : json['context'],
        'files': !exists(json, 'files') ? undefined : (mapValues(json['files'], PulledFileFromJSON)),
        'properties': !exists(json, 'properties') ? undefined : (mapValues(json['properties'], PulledPropertyFromJSON)),
    };
}

export function PulledConfigDataToJSON(value?: PulledConfigData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'generatedOn': value.generatedOn,
        'account': value.account,
        'repo': value.repo,
        'context': value.context,
        'files': value.files === undefined ? undefined : (mapValues(value.files, PulledFileToJSON)),
        'properties': value.properties === undefined ? undefined : (mapValues(value.properties, PulledPropertyToJSON)),
    };
}


