import { createStyles } from "@material-ui/core";
import theme from "../../theme/theme";

export const styles = createStyles({

  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },

  imageGrid: {
    width: "100%"
  },

  imageTile: { },

  tileBar: {
    background: "none"
  },

  deleteButton: {
    fontSize: 40,
    color: theme.palette.error.main
  }

});
