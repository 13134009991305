import { createStyles } from "@material-ui/core";
import theme from "../../theme/theme";

export const styles = createStyles({

  root: {
    minHeight: 100,
    margin: 20,
    padding: 25,
    "& .MuiCardContent-root:last-child": {
      padding: 0
    }
  },

  cardContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: 20
  },

  editImagesContainer: {
    width: "100%"
  },

  imageListContainer: {
    marginTop: 10
  },

  imageUploadContainer: {
    marginTop: 10
  },

  textField: {
    flexGrow: 1
  },

  switch: {},

  switchLabel: {
    marginRight: "auto",
    fontSize: 20
  },

  titleArea: {
    flexGrow: 1,
    width: "75%"
  },

  keyText: {},

  valueText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "90%"
  },

  clearButton: {
    alignSelf: "flex-start",
    marginRight: 20,
    padding: "15px 20px",
    letterSpacing: 0.1,
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main
  },

  editButton: {
    alignSelf: "flex-start",
    padding: "15px 20px",
    color: theme.palette.primary.main,
    letterSpacing: 0.1
  },

  cancelButton: {
    alignSelf: "flex-start",
    padding: "15px 20px",
    marginLeft: 20,
    color: theme.palette.primary.main,
    letterSpacing: 0.1
  },

  saveButton: {
    alignSelf: "flex-start",
    padding: "15px 20px",
    marginLeft: 20,
    border: `1px solid ${theme.palette.grey[100]}`,
    color: theme.palette.common.white,
    fontWeight: "bold",
    letterSpacing: 0.1
  },

  buttonIcon: {
    marginLeft: 15
  }

});
