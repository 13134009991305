/* tslint:disable */
/* eslint-disable */
/**
 * ConfigHub API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RepositoryOverallInfo,
    RepositoryOverallInfoFromJSON,
    RepositoryOverallInfoToJSON,
    RepositorySpecificInfo,
    RepositorySpecificInfoFromJSON,
    RepositorySpecificInfoToJSON,
    SystemInfo,
    SystemInfoFromJSON,
    SystemInfoToJSON,
} from '../models';

export interface GetRepositoryInfoRequest {
    repositoryDate?: Date;
    tag?: string;
    clientVersion?: string;
    pretty?: boolean;
    files?: boolean;
    filesGlob?: string;
    contextElements?: boolean;
    contextLabels?: string;
}

export interface GetSystemInfoRequest {
    clientVersion?: string;
    pretty?: boolean;
}

export interface ListRepositoriesInfoRequest {
    clientVersion?: string;
    pretty?: boolean;
}

/**
 * 
 */
export class InfoApi extends runtime.BaseAPI {

    /**
     * Gets information about a specific repository. It allows for glob syntax search of configuration files, and context definition.
     * Get repository info
     */
    async getRepositoryInfoRaw(requestParameters: GetRepositoryInfoRequest): Promise<runtime.ApiResponse<RepositorySpecificInfo>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.repositoryDate !== undefined && requestParameters.repositoryDate !== null) {
            headerParameters['Repository-Date'] = String(requestParameters.repositoryDate);
        }

        if (requestParameters.tag !== undefined && requestParameters.tag !== null) {
            headerParameters['Tag'] = String(requestParameters.tag);
        }

        if (requestParameters.clientVersion !== undefined && requestParameters.clientVersion !== null) {
            headerParameters['Client-Version'] = String(requestParameters.clientVersion);
        }

        if (requestParameters.pretty !== undefined && requestParameters.pretty !== null) {
            headerParameters['Pretty'] = String(requestParameters.pretty);
        }

        if (requestParameters.files !== undefined && requestParameters.files !== null) {
            headerParameters['Files'] = String(requestParameters.files);
        }

        if (requestParameters.filesGlob !== undefined && requestParameters.filesGlob !== null) {
            headerParameters['Files-Glob'] = String(requestParameters.filesGlob);
        }

        if (requestParameters.contextElements !== undefined && requestParameters.contextElements !== null) {
            headerParameters['Context-Elements'] = String(requestParameters.contextElements);
        }

        if (requestParameters.contextLabels !== undefined && requestParameters.contextLabels !== null) {
            headerParameters['Context-Labels'] = String(requestParameters.contextLabels);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RepositorySpecificInfoFromJSON(jsonValue));
    }

    /**
     * Gets information about a specific repository. It allows for glob syntax search of configuration files, and context definition.
     * Get repository info
     */
    async getRepositoryInfo(requestParameters: GetRepositoryInfoRequest): Promise<RepositorySpecificInfo> {
        const response = await this.getRepositoryInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets details of ConfigHub License and version
     * Get system info
     */
    async getSystemInfoRaw(requestParameters: GetSystemInfoRequest): Promise<runtime.ApiResponse<SystemInfo>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.clientVersion !== undefined && requestParameters.clientVersion !== null) {
            headerParameters['Client-Version'] = String(requestParameters.clientVersion);
        }

        if (requestParameters.pretty !== undefined && requestParameters.pretty !== null) {
            headerParameters['Pretty'] = String(requestParameters.pretty);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/info/system`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SystemInfoFromJSON(jsonValue));
    }

    /**
     * Gets details of ConfigHub License and version
     * Get system info
     */
    async getSystemInfo(requestParameters: GetSystemInfoRequest): Promise<SystemInfo> {
        const response = await this.getSystemInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists full details of settings for all defined repositories.
     * List all respositories info
     */
    async listRepositoriesInfoRaw(requestParameters: ListRepositoriesInfoRequest): Promise<runtime.ApiResponse<Array<RepositoryOverallInfo>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.clientVersion !== undefined && requestParameters.clientVersion !== null) {
            headerParameters['Client-Version'] = String(requestParameters.clientVersion);
        }

        if (requestParameters.pretty !== undefined && requestParameters.pretty !== null) {
            headerParameters['Pretty'] = String(requestParameters.pretty);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/info/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepositoryOverallInfoFromJSON));
    }

    /**
     * Lists full details of settings for all defined repositories.
     * List all respositories info
     */
    async listRepositoriesInfo(requestParameters: ListRepositoriesInfoRequest): Promise<Array<RepositoryOverallInfo>> {
        const response = await this.listRepositoriesInfoRaw(requestParameters);
        return await response.value();
    }

}
