import LocalizedStrings, { LocalizedStringsMethods } from "localized-strings";

/**
 * Interface describing localized strings
 */
export interface IStrings extends LocalizedStringsMethods {
  appTitle: string;
  logout: string;
  removeSelection: string;
  contextHasOverridingValues: string;
  clearAllOverridingValues: string;
  confirmClearAll: string;
  value: string;
  clearOverridingValue: string;
  confirmClear: string;
  edit: string;
  save: string;
  cancel: string;
  successfulUpdate: string;
  successfulClear: string;
  errorInUpdate: string;
  errorInClear: string;
  successfulUpload: string;
  errorInUploading: string;
  true: string;
  false: string;
  noImages: string;
  imageAlt: string;
  dragImagesOrClick: string;
}

const strings: IStrings = new LocalizedStrings({
  en: require("./en.json"),
  fi: require("./fi.json")
});

export default strings;
