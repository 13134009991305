import * as React from "react";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ReduxActions, ReduxState } from "../../store";
import { PredefinedElement, setPredefinedElements, setRepositoryInfo } from "../../actions/repository";
import Api from "../../api/api";
import { RepositorySpecificInfo } from "../../generated/client";
import { AccessToken } from "../../types";
import Keycloak from "keycloak-js";


/**
 * Component props
 */
interface Props {
  keycloak: Keycloak;
  accessToken: AccessToken;
  children?: React.ReactNode;
  repositoryInfo?: RepositorySpecificInfo;
  setRepositoryInfo: typeof setRepositoryInfo;
  predefinedElements: PredefinedElement[];
  setPredefinedElements: typeof setPredefinedElements;
};

/**
 * Component state
 */
interface State {
  error?: Error;
}

/**
 * Component for fetching initial data
 */
class StoreInitializer extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component did mount life cycle method
   */
  public componentDidMount = () => {
    this.fetchData();
  }

  /**
   * Component did update life cycle method
   *
   * @param prevProps previous component props
   */
  public componentDidUpdate = (prevProps: Props) => {
    if (!prevProps.accessToken && this.props.accessToken) {
      this.fetchData();
    }
  }

  /**
   * Component render method
   */
  public render = () => {
    const { repositoryInfo, children } = this.props;

    return (
      repositoryInfo ?
        children :
        null
    );
  }

  /**
   * Fetch app data
   */
  private fetchData = async () => {
    try {
      const { accessToken, setRepositoryInfo, setPredefinedElements } = this.props;
      if (!accessToken) {
        return;
      }
      const infoApi = Api.getInfoApi();

      const repositoryInfo = await infoApi.getRepositoryInfo({ contextElements: true });
      setRepositoryInfo(repositoryInfo);

      const predefinedElements = this.getPredefinedElements();
      if (predefinedElements) {
        setPredefinedElements(predefinedElements);
      }

    } catch (error) {
      this.setState({ error });
    }
  }

  /**
   * Gets predefined elements if any are found in environment variables
   * 
   * @returns predefined elements as array if any are found, otherwise undefined
   */
  private getPredefinedElements = (): PredefinedElement[] | undefined => {
    const predefinedElementsString = process.env.REACT_APP_CONFIG_HUB_PREDEFINED_CONTEXT_ELEMENTS;
    if (predefinedElementsString) {
      const predefinedElements = predefinedElementsString
        .split(",")
        .filter(value => !!value);

      if (predefinedElements.length > 0) {
        const elements: (PredefinedElement | undefined)[] = predefinedElements.map(element => {
          const keyValuePair = element.split(":");
          if (
            keyValuePair.length < 2 ||
            !keyValuePair[0] ||
            !keyValuePair[1]
          ) {
            console.error("Incorrect predefined element key-value pair found. It will not be applied.", keyValuePair);
            return undefined;
          }

          return { name: keyValuePair[0], value: keyValuePair[1] };
        });

        return elements.filter(value => !!value) as PredefinedElement[];
      }
    }

    return undefined;
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 * @returns state from props
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken as AccessToken,
  keycloak: state.auth.keycloak as Keycloak,
  repositoryInfo: state.repository.repositoryInfo,
  predefinedElements: state.repository.predefinedElements
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
  setRepositoryInfo: (repositoryInfo: RepositorySpecificInfo) => dispatch(setRepositoryInfo(repositoryInfo)),
  setPredefinedElements: (predefinedElements: PredefinedElement[]) => dispatch(setPredefinedElements(predefinedElements))
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreInitializer as any);
