/**
 * Login action
 */
export const LOGIN = "LOGIN";
export type LOGIN = typeof LOGIN;

/**
 * Logout action
 */
export const LOGOUT = "LOGOUT";
export type LOGOUT = typeof LOGOUT;

/**
 * Set locale action
 */
export const SET_LOCALE = "SET_LOCALE";
export type SET_LOCALE = typeof SET_LOCALE;

/**
 * Set repository info action
 */
export const SET_REPOSITORY_INFO = "SET_REPOSITORY_INFO";
export type SET_REPOSITORY_INFO = typeof SET_REPOSITORY_INFO;

/**
 * Set predefined elements action
 */
export const SET_PREDEFINED_ELEMENTS = "SET_PREDEFINED_ELEMENTS";
export type SET_PREDEFINED_ELEMENTS = typeof SET_PREDEFINED_ELEMENTS;