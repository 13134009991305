import { createStyles } from "@material-ui/core";

export const styles = createStyles({

  root: {
    flexGrow: 1,
    width: "100%",
    overflowY: "auto"
  },

  skeleton: {
    height: 100,
    margin: 20,
    backgroundColor: "rgba(0, 0, 0, .05)",
    borderRadius: 4
  }

});
